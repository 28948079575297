import React, { useRef, useState } from 'react';
import "./Navbar.css"
import Toggle from '../Toggle/Toggle'
import { Link } from 'react-scroll';
import { BiMenuAltRight } from "react-icons/bi";
import { themeContext } from '../../Context';
import { useContext } from 'react';

import useOutsideAlerter from '../../Hooks/useOutsideAlerter';
import { getMenuStyles } from '../../Utils/menu';





function Navbar() {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const [menuOpened, setMenuOpened] = useState(false);
   
    const menuRef = useRef()

    useOutsideAlerter(
        {
            menuRef,
            setMenuOpened
        }
    )

    return (

        <div className='n-wrapper' id="Navbar"
          
        >
            <div className="container innerWidth flexCenter">
                <div className='n-left'>
                    <div className='n-name'>ANURAG</div>

                    <Toggle />

                </div>
                <div className='n-right '>
                    <div className="n-list  ">
                        <ul
                            className='menu flexCenter'
                            ref={menuRef}
                            style={getMenuStyles(menuOpened)}
                        >
                            <Link className='link' spy={true} to='Navbar' smooth={true} activeClass='activeClass'>
                                <li style={{ fontWeight: "bold" }}>Home</li>
                            </Link>
                            <Link className='link' spy={true} to='Services' smooth={true} activeClass='activeClass'>
                                <li style={{ fontWeight: "bold" }}>Services</li>
                            </Link>
                            <Link className='link' spy={true} to='Experience' smooth={true} activeClass='activeClass'>
                                <li style={{ fontWeight: "bold" }}>Experience</li>
                            </Link>
                            <Link className='link' spy={true} to='Protfolio' smooth={true} activeClass='activeClass'>
                                <li style={{ fontWeight: "bold" }}>Projects</li>
                            </Link>
                            <Link className='link' spy={true} to='Testimonials' smooth={true} activeClass='activeClass'>
                                <li style={{ fontWeight: "bold" }} >Education</li>
                            </Link>

                            <Link spy={true} to='Contact' smooth={true} activeClass='activeClass' className='button n-button'>
                                Contact
                            </Link>
                        </ul>
                    </div>

                </div>
                {/* for medium and small screens */}
                <div
                    className="menuIcon " style={{top:"3.5px"}}
                    onClick={() => setMenuOpened((prev) => !prev)}
                >
                    <BiMenuAltRight size={28} style={{color: darkMode ? 'white' : ''}}/>
                </div>
            </div>

        </div>
    )


}

export default Navbar