export const getMenuStyles = (menuOpened) => {
  if (document.documentElement.clientWidth <= 640) {
    return {
      right: !menuOpened && "-150%",
      left: menuOpened && "5.9rem",
      padding: menuOpened && "1.2rem",
      position: menuOpened && "fixed",
      top: menuOpened && "3rem",
      height: menuOpened && "20rem"
    };
  }
};